; (function ($, document, window, undefined) {
    var pluginName = 'ModalWindow',
         defaults = {
             'padding': 20,
             'easing': 'ease-in-out'
         };
    
    function ModalWindow(element, options) {
        this.element = $(element);
        this.modalWindow = null;
        this.options = $.extend({}, defaults, options);
        this.init();
    }

    ModalWindow.prototype.init = function () {
        this.addContainingElements();
        

        if($(window).width() > 767 ) {
            this.setUIInteractions();
        }
            
        
        this.images = this.element.find('.images-link').find('img');
        this.currentId = 0;
        this.animating = false;
        console.log('images', this.images, 'banan');
    };

    ModalWindow.prototype.setUIInteractions = function () {
        this.element.find('.images-link').click(this.openModal.bind(this));
        this.modalWindow.find('.container').click(this.closeModal.bind(this));
        this.modalWindow.find('.arrow-next').click(this.clickNavigate.bind(this));
        this.modalWindow.find('.arrow-prev').click(this.clickNavigate.bind(this));
        $('body').on('keydown', this.keyNavigate.bind(this));
    };

    ModalWindow.prototype.keyNavigate = function(e) {
        if( ! this.animating) {
            if(e.keyCode === 37) {
                this.fadeActiveImage(false);
            } else if(e.keyCode === 39) {
                    this.fadeActiveImage(true);
            }
        }
    };

    ModalWindow.prototype.clickNavigate = function(e) {
        var arrowClass = $(e.target)[0].className.split(' ');

        e.preventDefault();

        if( ! this.animating) {
            if(arrowClass[0] === 'arrow-next') {
                this.fadeActiveImage(true);
            } else {
                this.fadeActiveImage(false);
            }
        }
    };

    ModalWindow.prototype.fadeActiveImage = function(next) {
        var self = this;

        this.animating = true;

        if(next) {
            this.modalWindow.find('.next').attr('src', this.getNextImageSrc(this.currentId));
        } else {
            this.modalWindow.find('.next').attr('src', this.getPreviousImageSrc(this.currentId));
        }

        this.modalWindow.find('.active').transition({
            'opacity': 0
        }, 1000)
        .promise()
        .done(function() {
            self.modalWindow.find('.active')
            .removeClass('active')
            .addClass('bottom');

            self.modalWindow.find('.next')
            .removeClass('next')
            .addClass('active');

            self.modalWindow.find('.bottom')
            .removeClass('bottom')
            .addClass('next')
            .css('opacity', 1);

            self.animating = false;
        });        
    };

    ModalWindow.prototype.openModal = function (e) {
        e.preventDefault();

        var activeImageId = $(e.target).data('id'),
            self = this;

        console.log('id', activeImageId);
        self.populateContainer(activeImageId);

        if ( ! this.modalWindow.hasClass('modal-open')) {
            this.modalWindow
            .addClass('modal-open')
            .css({
                'display': 'block',
                'opacity': 0
            })
            .transition({
                'opacity': 1
            }, 500, this.options.easing);
        }
    };

    ModalWindow.prototype.closeModal = function (e) {
        var self = this;
        if (this.modalWindow.hasClass('modal-open')) {
            this.modalWindow
            .removeClass('modal-open')
            .transition({
                'opacity': 0
            }, 500, this.options.easing)
            .promise()
            .done(function () {
                self.modalWindow.css({
                    'display': 'none'
                });
            });
        }
    };

    ModalWindow.prototype.populateContainer = function (id) {
        var activeImageSrc = $(this.images[id]).parent().attr('href');

        this.modalWindow.find('img').remove();
        this.appendImageContainer('next', '');
        this.appendImageContainer('active', activeImageSrc);
        this.currentId = id;
    };

    ModalWindow.prototype.getNextImageSrc = function(activeId) {
        if(activeId === this.images.length - 1) {
            this.currentId = 0;
            return $(this.images[this.currentId]).parent().attr('href');
        } else {
            this.currentId = activeId + 1;
            return $(this.images[this.currentId]).parent().attr('href');
        }
    };

    ModalWindow.prototype.getPreviousImageSrc = function(activeId) {
        if(activeId === 0) {
            this.currentId = 8;
            return $(this.images[this.currentId]).parent().attr('href');
        } else {
            this.currentId = activeId - 1;
            return $(this.images[this.currentId]).parent().attr('href');
        }
    };

    ModalWindow.prototype.appendImageContainer = function(type, src) {
        this.modalWindow.find('.img-container').append('<img src="' + src + '" class="' + type + '" />');
    };

    ModalWindow.prototype.addContainingElements = function () {
        $('body').append('<div id="modal-window"><div class="arrow-next icon-arrow-right-thin"></div><div class="arrow-prev icon-arrow-left-thin"></div><div class="container"><div class="img-container"></div></div></div>');
        this.modalWindow = $('#modal-window');
    };

    ModalWindow.prototype.styleContainingElements = function () {
        this.modalWindow.css({
            'padding': this.options.padding + 'px'
        });
    };

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' + pluginName, new ModalWindow(this, options));
            }
        });
    };
})(jQuery, document, window, undefined);